import {
  ADD_PLAYLIST_TO_SCREEN,
  ADD_SCREEN_REQUEST,
  ADD_SCREEN_SUCCESS,
  CLIENT_SCREENS_REQUEST,
  CLIENT_SCREENS_SUCCESS,
  DELETE_SCREEN,
  FIND_ONE_SCREEN,
  SCREEN_FLAG_OFF,
  SCREEN_REQUEST,
  SCREEN_REQUEST_FAILED,
  SCREEN_REQUEST_SUCCESS,
  SCREEN_STATUS_ACTIVE,
  SCREEN_STATUS_DEACTIVE,
  UNCHECK_ASSIGNED_SCREEN,
  UPDATE_SCREEN_SUCCESS,
} from "../constant";
const initial = {
  screens: [],
  screen: {},
  screenLoading: false,
  isScreenAdded: false,
  activeScreens: 0,
  deactiveScreens: 0,
  isScreenDeleted: false,
  isAdded: false,
  isUnChecked: false,
  mac: '',
  clientScreens:[]
};


export const screenReducer = (state = initial, action) => {
  function findIndex(id){
    let _index= state.screens.findIndex(_screen=>_screen._id==id);
    return _index;
  }
  switch (action.type) {
    case ADD_SCREEN_REQUEST:
      return {
        ...state,
        isScreenAdded: true,
      };
    case ADD_SCREEN_SUCCESS:
      return {
        ...state,
        isScreenAdded: false,
        isAdded: true,
        screens: [...state.screens, action.payload],
        mac: action.payload.mac
      };
      case UPDATE_SCREEN_SUCCESS:
        state.screens[findIndex(action.payload._id)]={...action.payload}
        return {
          ...state,
          screens:state.screens
        }
    case SCREEN_REQUEST:
      return {
        ...state,
        screenLoading: true,
      };
    case SCREEN_REQUEST_SUCCESS:
      let newScreen = []
      action.payload.forEach(s => {
        newScreen.push({ ...s, screenAssigned: true })
      })
      return {
        ...state,
        screenLoading: false,
        screens: newScreen,
        activeScreens:state.screens.filter(s=>s.status==true).length,
        deactiveScreens:state.screens.filter(s=>s.status==false).length
      };
    case SCREEN_REQUEST_FAILED:
      return {
        ...state,
        screenLoading: false
      }
    case FIND_ONE_SCREEN:
      return {
        ...state,
        screen: { ...state.screens.find(s => s._id === action.payload), screenAssigned: false }
      }
    case ADD_PLAYLIST_TO_SCREEN:
      // const assingnedScreenIndex = state.screens.findIndex(s => s._id === action.payload._id);
      state.screens[findIndex(action.payload._id)] = { ...action.payload, screenAssigned: false }
      return {
        ...state,
        screens: state.screens,
        isUnChecked: true
      }
    case UNCHECK_ASSIGNED_SCREEN:
      const findUnCheckScreen = findIndex(action.payload)
      state.screens[findUnCheckScreen] = { ...state.screens[findUnCheckScreen], screenAssigned: true }
      return {
        ...state,
        screens: state.screens,
        isUnChecked: true
      }
    case SCREEN_FLAG_OFF:
      return {
        ...state,
        isUnChecked: false,
        isAdded: false,
        isScreenDeleted: false
      }
    case SCREEN_STATUS_ACTIVE:
      // const activeStatusIndex = state.screens.findIndex(s => s._id === action.payload._id)
      state.screens[findIndex(action.payload._id)] = { ...action.payload }
      return {
        ...state,
        screens: state.screens,
        activeScreens:state.screens.filter(s=>s.status==true).length,
      };
    case SCREEN_STATUS_DEACTIVE:
      // const DeactiveStatusIndex = state.screens.findIndex(s => s._id === action.payload._id)
      state.screens[findIndex(action.payload._id)] = { ...action.payload }
      return {
        ...state,
        screens: state.screens,
        deactiveScreens:state.screens.filter(s=>s.status==false).length
      };
    case DELETE_SCREEN:
      return {
        ...state,
        isScreenDeleted: true,
        screens: state.screens.filter(screen => screen._id !== action.payload)
      }
    case CLIENT_SCREENS_REQUEST:
      return {
        ...state,
        screenLoading: true,
      }
    case CLIENT_SCREENS_SUCCESS:
      let newClientScreens = []
      action.payload.forEach(s => {
        newClientScreens.push({ ...s, screenAssigned: true })
      })
      return {
        ...state,
        screenLoading: false,
        clientScreens: newClientScreens,
      };

    default:
      return state;
  }
};
