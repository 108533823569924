import React, { useEffect, useState } from "react";
import "./layout.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from "../sidebar/Sidebar";
// import { Box } from "@mui/system";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as CalendarIcon } from "../../Assets/images/calender.svg";
import { logout, updateUser, userFlagOff } from "../../Redux/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { imgUrl } from "../../api/api";
import toast from "react-hot-toast";
import {makeStyles} from '@material-ui/core';
import { CalendarMonthOutlined } from "@mui/icons-material";
const useStyle=makeStyles((theme)=>{
  return {
    sideBar:{
      [theme.breakpoints.down('sm')]:{
        width:'70px !important'
      },
      // [theme.breakpoints.up('sm')]:{
      //   width:'18vw !important'
      // }
    }
  }
})
function Layout({ children, title, user }) {
  const classes=useStyle();
  const {isUserAdded}=useSelector(state=>state.User);
  const [day, setDay] = useState("");
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [username,setUsername]=useState(user.username);
  const [password,setPassword]=useState(user.password);
  const [confirmpassword,setConfirmPassword]=useState('');
  const [email,setEmail]=useState(user.email);
  const open = Boolean(anchorEl);
  const [image,setImage]=useState(`${user?.image?.url}`);
  const [imgFile,setImgFile]=useState('');
  const [openDrawer,setOpenDrawer]=useState(false)
  useEffect(()=>{
    if(isUserAdded){
      toast.success('User updated!');
      setOpenProfileDialog(false);
      dispatch(userFlagOff());
    }
  },[isUserAdded])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
    // setOpenProfileDialog(true);
  };
  const openprofile = () => {
    setAnchorEl(null);
    setOpenProfileDialog(true);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getDay();
  }, []);
  const getDay = () => {
    const date = new Date();
    setDate(date.getDate());
    setYear(date.getFullYear());
    // console.log("day", date.getDay());
    switch (date.getDay()) {
      case 1:
        setDay("Monday");
        break;
      case 2:
        setDay("Tuesday");
        break;
      case 3:
        setDay("Wednesday");
        break;
      case 4:
        setDay("Thursday");
        break;
      case 5:
        setDay("Friday");
        break;
      case 6:
        setDay("Saturday");
        break;
      case 0:
        setDay("Sunday");
        break;
      default:
        setDay("");
    }
    switch (date.getMonth()) {
      case 0:
        setMonth("Jan");
        break;
      case 1:
        setMonth("Feb");
        break;
      case 2:
        setMonth("Mar");
        break;
      case 3:
        setMonth("Apr");
        break;
      case 4:
        setMonth("May");
        break;
      case 5:
        setMonth("Jun");
        break;
      case 6:
        setMonth("Jul");
        break;
      case 7:
        setMonth("Aug");
        break;
      case 8:
        setMonth("Sep");
        break;
      case 9:
        setMonth("Oct");
        break;
      case 10:
        setMonth("Nov");
        break;
      case 11:
        setMonth("Dec");
        break;
      default:
        setMonth("");
    }
  };
  const browseUserImage=(e)=>{
    setImage(URL.createObjectURL(e.target.files[0]));
    setImgFile(e.target.files[0]);
    // console.log('browse function=>',imgFile)
  }
  const update=()=>{
    // console.log('update function=>',imgFile)
    const form=new FormData();
    form.append('username',username);
    form.append('password',password);
    form.append('email',email);
    form.append('content',imgFile);
    dispatch(updateUser(user._id,form));
  }
  
  return (
    <>
      <div className="layout-container">
        {
          user.email=='dummy-random@gmail.com' ? <></> : 
        <div className={`side-bar ${classes.sideBar}`}>
          <Sidebar user={user} />
        </div>
        }
        <div className="body">

          <div className="body-header">
            <div className="body-header-left">
              {/* <div className="titles"> */}
              <div className="title-logo"></div>
              <p className="form-title">{title}</p>
              {/* </div> */}
            </div>
            <div className="body-header-right">
              {
                user.email=='dummy-random@gmail.com' ? <></> : 
              <div className={'mobile-menu'}>
              <MenuIcon className="menu-icon" onClick={()=>setOpenDrawer(true)}/>
              </div>
              }
              <div className="body-header-right-inner">
                {/* <Box mr={3}>
                  <button className="upgradePro">Upgrade to Pro</button>
                </Box> */}
                <Box
                  className="time-day"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CalendarMonthOutlined
                    sx={{
                      color: "var(--primary-color)",
                      marginTop: "-5px",
                    }}
                  />
                  <Typography
                    sx={{
                      paddingLeft: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {`${day},${date} ${month} ${year}`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ml: 3,
                  }}
                >
                  {
                   user?.image==undefined || user?.image?.url==='' ?
                  
                  <AccountCircleIcon
                    style={{ color: "var(--primary-color)", marginTop: "-5px" ,cursor:'pointer'}}
                    onClick={handleClick}
                    aria-controls={open ? "content-menu" : undefined}
                    aria-haspopup={"true"}
                    aria-expanded={open ? "true" : undefined}
                    id="content-menu-btn"
                  />
                  :
                  <img
                   src={`${user?.image?.url}`}
                   style={{height:'30px',width:'30px',borderRadius:'50%' ,color: "#551fff", marginTop: "-5px" ,cursor:'pointer'}}
                    onClick={handleClick}
                    aria-controls={open ? "content-menu" : undefined}
                    aria-haspopup={"true"}
                    aria-expanded={open ? "true" : undefined}
                    id="content-menu-btn"
                  />
}
                  <Typography
                    sx={{
                      paddingLeft: "5px",
                      color: "#6B7A99",
                      fontSize: "13px",
                    }}
                  >
                    {user?.username}
                  </Typography>
                  <Menu
                    id={"content-menu"}
                    anchorEl={anchorEl}
                    open={open}
                    MenuListProps={{
                      "aria-labelledby": "content-menu-btn",
                    }}
                    onClose={handleClose}
                    keepMounted
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem onClick={openprofile}>Profile</MenuItem>
                    {/* <MenuItem onClick={()=> window.open('http://localhost:3000/web-player','_blank')}>Web Player</MenuItem> */}
                    <MenuItem onClick={() => dispatch(logout())}>
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
                <Box
                  sx={{
                    ml: 3,
                  }}
                >
                  <NotificationsIcon
                    sx={{
                      color: "var(--primary-color)",
                      background: "white",
                      borderRadius: "50%",
                      padding: "8px",
                    }}
                  />
                </Box>
              </div>
            </div>
          </div>

          <div className="body-body">
            <div className="body-body-scroll-able">{children}</div>
          </div>
        </div>
      </div>

      <Dialog
        maxWidth={"lg"}
        open={openProfileDialog}
        onClose={() => setOpenProfileDialog(false)}
        sx={{
          zIndex:'100000000'
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "7px solid var(--primary-color)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <AccountCircleOutlinedIcon
          sx={{
            color:"var(--primary-color)"
          }}
          />
          {/* <img src={"/images/userOutlinedColorIcon.png"} /> */}
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "26px",
              lineHeight: "35px",
              color: "#15192C",
            }}
          >
            User Profile
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "50vw",
              height: "40vh",
              padding: "20px",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                // border: "1px solid black",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "80%",
                  width: "100%",
                  // border: "1px solid black",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: "30%",
                    // borderLeft: "1px solid black",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "95%",
                      borderRadius: "20px",
                    }}
                  >
                    {
                      image!=='' ? 
                      <img
                        src={
                         `${image}`
                        }
                       
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "15px",
                          objectFit: "cover",
                        }}
                      /> :
                      <AccountCircleOutlinedIcon
                      sx={{color:"var(--primary-color)",fontSize:'200px'}}
                      
                      />
                    }
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "100%",
                    width: "70%",
                    // borderLeft: "1px solid black",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <FormControl variant={'standard'} fullWidth>
                  <InputLabel htmlFor="username">Username *</InputLabel>
                  <Input
                  id={'username'}
                  name="username"
                    type={"text"}
                    value={username}
                    onChange={(e)=>setUsername(e.target.value)}
                  />
                  </FormControl>
                  <FormControl variant={'standard'} fullWidth>
                  <InputLabel htmlFor='password'>Password *</InputLabel>
                  <Input
                  id="password"
                  name="password"
                    type={"password"}
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                  />
                  </FormControl>
                  <FormControl variant={'standard'} fullWidth>
                  <InputLabel htmlFor="cpassword">Confirm Password *</InputLabel>
                  <Input
                  id='cpassword'
                    type={"password"}
                    value={confirmpassword}
                    onChange={(e)=>setConfirmPassword(e.target.value)}
                  />
                  </FormControl>
                  <FormControl variant={'standard'} fullWidth>
                    <InputLabel htmlFor="email">Email *</InputLabel>
                  <Input
                    id="email"
                    type={"email"}
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  // border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <input hidden type="file" id="imagebrowse" onChange={browseUserImage}/>
                <Button
                  variant={"outlined"}
                 className="profile-upload-btn"
                  onClick={()=>
                  document.getElementById('imagebrowse').click()
                  }
                >
                  Upload Profile Photo
                </Button>
                <Button
                  variant={"contained"}
                 className="profile-save-btn"
                  onClick={update}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Drawer
      open={openDrawer}
      anchor={'left'}
      onClose={()=>setOpenDrawer(false)}
      >
          <div className="drawer-container">
            <Sidebar user={user} />
          </div>
      </Drawer>
    </>
  );
}
export default Layout;
