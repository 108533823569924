import React from 'react';
import './SupportPage.css'; // Optional: for styling

const SupportPage = () => {
  return (
    <div className="support-page">
      <h1>Welcome to SNS Group Admin Support</h1>
      <p>We’re here to help you get the most out of SNS Group Admin. If you have any questions, issues, or feedback, please explore the resources below or contact us directly.</p>

      <section>
        <h2>FAQs</h2>
        <p>Find answers to common questions about SNS Group Admin:</p>
        <ul>
          <li>
            <strong>How do I create an account?</strong>
            <p>Contact our support team to get credentials for login. We will create an account and share the credentials with you via email.</p>
          </li>
          <li>
            <strong>How do I reset my password?</strong>
            <p>Contact our support team to reset your password. We will assist you with the process.</p>
          </li>
        </ul>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>If you can’t find what you’re looking for, our support team is ready to assist you.</p>
        <ul>
          <li><strong>Email:</strong> contact@snsgroupe.fr</li>
          <li><strong>Phone:</strong> +33 01 80 91 59 14</li>
        </ul>
      </section>

      <section>
        <h2>Troubleshooting</h2>
        <p>Experiencing issues? Follow these steps:</p>
        <ul>
          <li>
            <strong>App crashing or not launching?</strong>
            <p>Clear your app cache. If the issue persists, send us an email with your credentials, and we will assist you.</p>
          </li>
        </ul>
      </section>

      <section>
        <h2>Account Creation</h2>
        <p>To create an account, send us an email at <strong>contact@snsgroupe.fr</strong>. We will create an account and share the credentials with you.</p>
      </section>

      <section>
        <h2>Feedback</h2>
        <p>We value your feedback! Let us know how we can improve SNS Group Admin:</p>
        <p>Write an email and send it to us at <strong>contact@snsgroupe.fr</strong>.</p>
      </section>

      <section>
        <h2>Privacy Policy & Terms of Service</h2>
        <ul>
          <li>
            <a href="https://snsplayer.com/privacypolicy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://snsplayer.com/privacypolicy" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>
          </li>
        </ul>
      </section>

      <footer>
        <p>Thank you for using SNS Group Admin! We’re committed to providing you with the best experience possible.</p>
      </footer>
    </div>
  );
};

export default SupportPage;