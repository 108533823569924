
 function ApplePrivacyPolicy() {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">1. Information We Collect</h2>
        <p>
          <strong>In Short:</strong> We collect personal information that you provide to us.
        </p>
        <p>
          We collect personal information that you voluntarily provide when you register for the <strong>SNS PLAYER</strong> app, participate in activities using the app, or contact us. This includes:
        </p>
        <ul className="list-disc ml-6">
          <li>Images you upload for dynamic display content.</li>
          <li>Email address and name if you contact us via email.</li>
        </ul>
        <p>
          <strong>Camera Permission:</strong> We use the camera permission to allow users to take pictures directly within the app for display on digital signage screens. These images are not shared with third parties unless explicitly authorized by the user.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">2. Processing Your Information</h2>
        <p>
          <strong>In Short:</strong> We process your information to provide, improve, and administer our services, communicate with you, and ensure security and fraud prevention.
        </p>
        <ul className="list-disc ml-6">
          <li>Deliver the services provided by the <strong>SNS PLAYER</strong> app.</li>
          <li>Enable features such as <strong>camera-based image capture for signage display</strong>.</li>
          <li>Provide customer support and improve user experience.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">3. Legal Bases for Processing</h2>
        <p>
          <strong>In Short:</strong> We process your information based on legal grounds.
        </p>
        <ul className="list-disc ml-6">
          <li><strong>Consent:</strong> You have given explicit consent for us to process your personal information.</li>
          <li><strong>Contractual Obligation:</strong> Processing is necessary to fulfill a contract with you.</li>
          <li><strong>Legal Obligation:</strong> We process your information to comply with legal requirements.</li>
          <li><strong>Legitimate Interests:</strong> We process information for our business interests while respecting your rights.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">4. Your Privacy Rights</h2>
        <p>
          <strong>In Short:</strong> You have specific rights regarding your personal information, depending on your location.
        </p>
        <p>You have the right to:</p>
        <ul className="list-disc ml-6">
          <li>Access, update, or delete your personal information.</li>
          <li>Request data portability and restrict processing of your information.</li>
        </ul>
        <p>Requests can be made directly through the app or by contacting us.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">5. Security Measures</h2>
        <p>
          <strong>In Short:</strong> We take security seriously and implement measures to protect your information.
        </p>
        <p>
          We employ industry-standard security measures, such as encryption and secure server hosting, to safeguard your data.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">6. Data Retention</h2>
        <p>
          <strong>In Short:</strong> We retain personal information for as long as necessary to fulfill the purposes outlined in this policy.
        </p>
        <p>
          Personal information is stored as long as required for the operation of our app and to comply with legal obligations.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">7. Data Transfers</h2>
        <p>
          <strong>In Short:</strong> We transfer personal information in compliance with legal standards.
        </p>
        <p>
          If your data is transferred internationally, we ensure appropriate safeguards are in place to protect your personal information.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">8. Updates to This Notice</h2>
        <p>
          <strong>In Short:</strong> We update this policy as needed to stay compliant with legal changes.
        </p>
        <p>
          We may update this Privacy Policy periodically. The latest version will always be available within the app.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">9. Contact Us</h2>
        <p>For any questions about this Privacy Policy, you can contact us via:</p>
        <p>
          📧 <strong>Email:</strong> <a href="mailto:contact@snsgroupe.fr" className="text-blue-600">contact@snsgroupe.fr</a>
        </p>
        <p>
          📍 <strong>Mail:</strong> SNS Groupe, 11 Avenue du Val de Fontenay, Fontenay-sous-Bois, 94120 France
        </p>
      </section>
    </div>
  );
}

export default ApplePrivacyPolicy